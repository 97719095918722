$intercalate-background-color: var(--intercalate-background-color);

.facebook-button-class {
  padding: 0.2rem 2rem;
  background: #1876f2;
  border: white;
  color: white;
  width: 100%;
  border-radius: 50rem !important;
  border: 1px solid transparent;
  font-family: "TrebuchetMS", "Trebuchet MS";
  i {
    margin-right: 8px;
  }
  height: 38px;
}

.login-area {
  background-color: white;
  -webkit-box-shadow: 7 4px 30px -6px #abbed1;
  -moz-box-shadow: 0 7px 30px -6px #abbed1;
  box-shadow: 0 7px 30px -6px #abbed1;
}

.login-background {
  background-image: linear-gradient(
    to right,
    $intercalate-background-color 50%,
    rgba(0, 0, 0, 0) 20%
  );
  padding-bottom: 3rem !important;
  padding-top: 3rem !important;
}

.desktop-login {
  visibility: visible;
}

.mobile-login {
  display: none;
}

@media screen and (max-width: 990px) {
  .desktop-login {
    display: none;
  }
  .mobile-login {
    display: contents;
  }
}

.nav-tabs-custom-login {
  width: 49%;
  text-align: center;
}

.new-customer-content {
  button {
    background: var(--primary-background-color);
    color: #ffffff;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0.75rem 1rem;
  }
  button:hover {
    color: #ffffff;
  }
}

.login-content {
  .login-form {
    background: #f0f0f0;
    padding: 2rem;
    border-radius: 0.75rem;
    button {
      background: var(--secondary-background-color);
      color: var(--text-color-with-secondary);
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      padding: 0.75rem 1rem;
    }
    .forgot-password {
      font-size: 14px;
      text-decoration: underline;
      color: var(--primary-background-color);
    }
    .form-group {
      input {
        border-radius: 0.45rem;
        border: none;
        background: #e1e1e1;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .login-background {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
    background: white !important;
  }
}

