.Toastify__progress-bar--default {
  background: var(--secondary-background-color) !important;
}

.product-details-content {
  margin-bottom: 1rem;
  h2 {
    font-size: 30px;
    font-weight: bold;
    color: var(--secondary-background-color);
  }
  .go-to-faq {
    cursor: pointer;
    text-decoration: underline;
    font-size: 15px;
    color: var(--secondary-background-color);
  }
  .auction-title {
    font-weight: 300;
    margin-right: 0.5rem;
    color: #929292;
  }
  .auction-content {
    font-weight: bold;
    color: var(--primary-background-color);
  }
  .description-lots {
    font-size: 15px;
    margin-top: 0.25rem;
  }
  .product-info {
    margin: 1rem 0rem;
    padding: 1rem 1rem;
    border-top: 1px solid rgb(190, 190, 190);
    border-bottom: 1px solid rgb(190, 190, 190);
    li {
      list-style-type: none;
      color: var(--primary-background-color);
      font-weight: bold;
      margin-bottom: 0.5rem;
      span {
        color: #929292;
        font-weight: normal;
        text-transform: uppercase;
      }
    }
  }
  .buy-checkbox-btn {
    display: grid !important;
    a {
      &:hover {
        text-decoration: none;
        opacity: 0.5;
      }
    }

    button {
      border: none;
      border: 1px solid black;
    }

    .winning {
      background-color: var(--button-offer-winning) !important;
      border-color: var(--button-offer-winning) !important;
      color: #fff !important;
    }

    .not_auctioned {
      background-color: var(--button-offer-not_auctioned) !important;
      border-color: var(--button-offer-not_auctioned) !important;
      color: #fff !important;
    }

    .auctioned {
      background-color: var(--button-offer-auctioned) !important;
      border-color: var(--button-offer-auctioned) !important;
      color: #fff !important;
    }

    .not_offered {
      background-color: transparent !important;
      border-color: var(--text-color-with-secondary) !important;
      color: var(--text-color-with-secondary) !important;
    }

    button {
      width: 100%;
    }

    .login-to-offer {
      background-color: var(--primary-background-color) !important;
      border-color: var(--primary-background-color) !important;
      color: var(--secondary-background-color) !important;
    }
  }

  .last-offers {
    font-size: 14px;
    p {
      font-size: 14px;
    }
  }

  .offer-type {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem;
    button {
      font-size: 11px !important;
      background-color: white !important;
      border: 1px solid var(--primary-background-color) !important;
      color: var(--primary-background-color) !important;

      /* El segundo boton tiene un margin-left -1px */
      &:nth-child(1) {
        margin-right: -1px;
        border-top-left-radius: 50rem !important;
        border-bottom-left-radius: 50rem !important;
      }

      &:nth-child(2) {
        margin-left: -1px;
        border-top-right-radius: 50rem !important;
        border-bottom-right-radius: 50rem !important;
      }

      &.active {
        background-color: var(--primary-background-color) !important;
        border: 1px solid var(--primary-background-color) !important;
        color: white !important;
      }
    }

    .no-border-right {
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }

    .no-border-left {
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
    }
  }
}

.products-details-tab {
  .timeclock {
    text-align: center;
    padding: 0.2rem;
    border-radius: 20px 20px 0px 0px;
    margin-top: 1rem;
    color: black;
    .first-div {
      display: flex;
      animation: none !important;
      justify-content: start;
      gap: 0.5rem;
      font-weight: bold;
      .item {
        animation: none !important;
        display: grid;
        span {
          font-size: 17px;
          font-weight: normal;
          &:first-child {
            margin-bottom: -5px;
            font-size: 19px;
            font-weight: bold;
          }
        }
      }
    }
  }

  .timeclock.warning {
    animation: changeColorAnimation 1s infinite;
  }

  .timeclock.normal {
    animation: none !important;
  }

  @keyframes changeColorAnimation {
    0% {
      color: #a10000;
    }
    50% {
      color: #df0404;
    }
    100% {
      color: #a10000;
    }
  }
}
