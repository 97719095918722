.communicate-with-us {
  a:hover {
    color: var(--text-color-with-secondary);
    text-decoration: none;
  }
  @media screen and (max-width: 768px) {
    margin: auto;
  }
}

.communicate-with-us-content {
  @media screen and (max-width: 768px) {
    text-align: center;
  }
}
