.title-box {
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  //margin: 0 auto;
  color: #323232;
  &.light {
    color: #fff;
  }
  .title {
    flex: 100%;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 30px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
  .border-left {
    flex: 50%;
    border-bottom: var(--height-border) solid var(--secondary-background-color);
    border-left: none;
  }
  .border-right {
    flex: 50%;
    border-bottom: 3px solid var(--secondary-background-color);
    border-left: none;
  }
}

@media screen and (max-width: 990px) {
  .title-box {
    .title {
      font-size: 20px;
    }
  }
}
