.faq,
.buy,
.sell {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 300;
  .title-box {
    margin-bottom: 2rem;
  }

  .accordion {
    border: none;
  }

  img {
    max-width: 100%;
  }

  h1 {
    display: flex;
    text-align: left;
    flex-wrap: wrap;

    @media screen and (max-width: 768px) {
      font-size: 28px;
    }
  }

  .accordion__button {
    background-color: var(--intercalate-background-color);
    color: black;
    text-transform: capitalize;
    position: relative;
    @media screen and (max-width: 768px) {
      font-size: 13px;
    }
    &::before {
      //plusiconinsteadacaret
      content: "+";
      border: none;
      margin-right: 5px;
      position: absolute;
      right: 0;
      top: 20%;
      bottom: 0;
      width: 30px;
      height: 30px;
      color: var(--text-color-with-primary);
      background: var(--primary-background-color);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      rotate: 45deg;
      -webkit-rotate: 45deg;
      -moz-rotate: 45deg;
      -ms-rotate: 45deg;
      -o-rotate: 45deg;
      transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
    }
  }
}
