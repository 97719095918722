.space {
  height: 60px;
}

.mobile-menu {
  background-color: var(--primary-background-color);
  position: relative;
  position: fixed;
  top: 0;
  z-index: 11;
  right: 0;
  left: 0;
  .header {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    button {
      background-color: transparent;
      border: none;
      &:focus {
        outline: none;
      }
    }
    .logo {
      height: 40px;
    }
  }
  .full-menu {
    position: absolute;
    left: 0px;
    right: 0px;
    z-index: 11;
    background-color: var(--primary-background-color);
    padding: 2rem 0rem;
    font-size: 15px;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    .pages {
      text-align: center;
      a {
        color: #fff;
        div {
          margin-bottom: 10px;
          letter-spacing: 0.1rem;
        }
      }
    }
    .user-pages {
      text-align: center;
      margin-top: 40px;
      a {
        color: var(--secondary-background-color);
        div {
          margin-bottom: 10px;
          letter-spacing: 0.1rem;
        }
      }
    }
  }
}

.mobile-menu-lv {
  background-color: var(--secondary-background-color);
  .user-pages {
    text-align: center;
    margin-top: 40px;
    a {
      color: white;
    }
  }
}

@media screen and (max-width: 350px) {
  .access-code-box .dialog .custom-input {
    display: block !important;
    .action {
      width: 100% !important;
      img {
        background-color: transparent !important;
      }
    }
  }
}
