$black-color: #222222;

#MyAccount {
  .nav {
    display: block !important;
  }

  .tab-pane table th {
    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
  }

  .signup-form {
    background-color: white;
    -webkit-box-shadow: 7 4px 30px -6px #abbed1;
    -moz-box-shadow: 0 7px 30px -6px #abbed1;
    box-shadow: 0 7px 30px -6px #abbed1;
    border-radius: 5px;
    color: #495057;
    padding: 1rem;
    .buttons {
      button {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.1rem;
        text-transform: uppercase;
        border: none;
        border-radius: 10px;
        padding: 0.5rem 1rem;
        color: var(--text-color-with-secondary);
        background-color: var(--secondary-background-color);
      }
      a {
        text-decoration: underline;
        font-size: 16px;
        text-transform: uppercase;
        color: var(--primary-background-color);
        letter-spacing: 1px;
      }
    }
    input,
    select {
      border-radius: 20px;
      background: transparent;
      height: 40px;
    }
  }

  .tab-content {
    width: 100%;
  }

  .side-menu {
    -webkit-box-shadow: 7 4px 14px -6px #abbed1;
    -moz-box-shadow: 0 7px 14px -6px #abbed1;
    box-shadow: 0 7px 14px -6px #abbed1;
    border-radius: 5px;
    height: max-content;
    width: 250px;

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    /* first a cursor default */
    .first-a {
      border-bottom: 1px solid #f1f1f1;
      margin-bottom: 1px;
      display: flex !important;
      align-items: end;
      @media screen and (max-width: 768px) {
        display: none !important;
        width: 100% !important;
      }
    }
  }

  .nav-tabs .nav-link.active {
    background-color: none;
    border-color: none !important;
    color: $black-color !important;
  }

  .nav-tabs-custom.nav-item.nav-link {
    background-color: none;
    border-color: none !important;
    color: lightgray;
  }

  .nav-tabs-custom.nav-item.nav-link.active {
    background-color: none;
    border-color: none !important;
    font-weight: bold;
  }

  .nav-tabs-custom.nav-item.nav-link.active::before {
    content: "";
    position: absolute;
    left: 0;
    width: 5px;
    height: 25px;
    border-radius: 3px;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .nav-tabs .nav-link .active span {
    color: var(--primary-background-color) !important;
  }

  @media screen and (max-width: 768px) {
    .nav-link {
      padding: 0.5rem 0.5rem !important;
      font-size: 14px;
    }
  }
}
