.carousel-control-prev,
.carousel-control-next {
  opacity: 1 !important;
}

@media screen and (max-width: 768px) {
  .carousel {
    margin-top: 0;
  }
}

.carousel {
  .carousel-item {
    max-height: 100vh;
  }
  .carousel-inner {
    max-height: 100vh;
    img {
      object-fit: cover;
    }
  }
}

.details-for-banner {
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.1);
  div {
    display: grid;
    img {
      max-width: 350px;
      margin: auto;
      margin-bottom: 1rem;
    }
    label {
      font-size: 36px;
      font-weight: 500;
      margin-bottom: 1rem;
    }
    button {
      border: none;
      min-width: 250px;
      padding: 0.5rem 4rem;
      width: max-content;
      margin: auto;
    }
  }

  @media screen and (max-width: 768px) {
    div {
      text-align: center;
      img {
        display: none;
      }
      label {
        font-size: 18px;
      }
      button {
        padding: 0.5rem 2rem;
        width: max-content;
        min-width: max-content;
        font-size: 14px;
      }
    }
  }
}
