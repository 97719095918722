#auction-detail-online {
  .offer-type {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.25rem;
    button {
      font-size: 11px !important;
      background-color: white !important;
      border: 1px solid var(--primary-background-color) !important;
      color: var(--primary-background-color) !important;

      /* El segundo boton tiene un margin-left -1px */
      &:nth-child(1) {
        margin-right: -1px;
      }

      &:nth-child(2) {
        margin-left: -1px;
      }

      &.active {
        background-color: var(--primary-background-color) !important;
        border: 1px solid var(--primary-background-color) !important;
        color: white !important;
      }
    }

    .no-border-right {
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }

    .no-border-left {
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
    }
  }

  .catalog-icon {
    @media screen and (max-width: 768px) {
      margin-top: 2rem;
      justify-content: center !important;
    }
  }

  .pb-60 {
    padding-bottom: 4rem;
  }

  .ptb-60 {
    padding-bottom: 4rem;
    padding-top: 4rem;
  }

  .box-top-subastas {
    background-color: white;
    -webkit-box-shadow: 7 4px 30px -6px #abbed1;
    -moz-box-shadow: 0 7px 30px -6px #abbed1;
    box-shadow: 0 7px 30px -6px #abbed1;
    padding: 8px;
  }

  .box-subastas {
    background-image: linear-gradient(
      to right,
      var(--intercalate-background-color) 50%,
      rgba(0, 0, 0, 0) 20%
    );
    padding: 25px;
    margin-top: 2rem;

    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
  }

  h2 {
    font-size: 30px;
    font-weight: bold;
  }
  .state-title {
    font-weight: 300;
    margin-right: 0.25rem;
    color: #929292;
  }
  .state-content {
    text-transform: uppercase;
    color: var(--secondary-background-color);
  }
  .date-from-to {
    margin-top: 1rem;
    margin-bottom: 1rem;
    background: #f0f0f0;
    padding: 1rem 1.5rem;
    border-radius: 0.75rem;
    font-size: 14px;
    color: #929292;
  }

  .card-lot-container {
    display: flex;
  }

  .card-lot {
    margin-bottom: 2rem;
    box-shadow: 0px 8px 16px 0px rgba(171, 190, 209, 0.4);
    padding: 0rem;
    border-radius: 8px;
    width: 100%;

    .card-lot-content {
      position: relative;
      height: 100%;
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: #fafafa;
      border-radius: 10px;
    }

    .product-image {
      position: relative;
      height: 100%;
      img {
        width: 100%;
        object-fit: cover;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        height: 100%;
        max-height: 600px;
      }
    }

    .carousel-item {
      img {
        width: 100% !important;
        object-fit: cover;
        height: 100%;
        max-height: 600px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
    }

    button {
      border-radius: 50rem;
      width: 100%;
      font-size: 13px;
      font-weight: bold;
      height: max-content;
    }

    .title-lot {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 0.25rem;
      a {
        color: black;
      }

      // in mobile no saltar de linea mostrar todo en varias lineas
      @media screen and (max-width: 768px) {
        white-space: normal;
        text-overflow: clip;
        overflow: visible;
      }
    }

    .quantity-lot {
      margin-bottom: 0px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 13px;
    }

    .description-without {
      margin-bottom: 0px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 13px;
    }

    .description-with {
      height: 25px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: 0;
      font-size: 13px;
    }

    .date-close-and-price {
      margin-bottom: 0px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 13px;
    }

    .best-offer-user {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 13px;
    }

    .actual-offer {
      margin-bottom: 0rem;
      font-size: 13px;
    }

    .winning {
      background-color: var(--button-offer-winning) !important;
      border-color: var(--button-offer-winning) !important;
    }

    .not_offered {
      background-color: var(--button-offer-not_offered) !important;
      border-color: var(--button-offer-not_offered) !important;
      color: var(--text-color-with-secondary) !important;
    }

    .not_auctioned {
      background-color: var(--button-offer-not_auctioned) !important;
      border-color: var(--button-offer-not_auctioned) !important;
    }

    .auctioned {
      background-color: var(--button-offer-auctioned) !important;
      border-color: var(--button-offer-auctioned) !important;
    }

    .login-to-offer {
      background-color: var(--primary-background-color) !important;
      border-color: var(--primary-background-color) !important;
    }

    input {
      border-radius: 50rem;
      height: 30px;
      font-size: 13px;
      border: 1px solid black;
    }
  }

  .auction-in-proccess {
    width: max-content;
    animation: zoominoutsinglefeatured 1s infinite;
    max-width: 100%;
  }

  .timeclock {
    background: #df0404;
    text-align: center;
    padding: 0.2rem;
    animation: changeColor 1s infinite;
    border-radius: 8px 8px 0px 0px;
    div {
      color: white;
      font-weight: bold;
    }
  }

  .timeclock.normal {
    background: #fff;
    animation: none !important;
    .first-div {
      display: flex;
      animation: none !important;
      justify-content: end;
      gap: 0.5rem;
      .item {
        animation: none !important;
        display: grid;
        span {
          color: black;
          font-size: 11px;
          font-weight: normal;
          &:first-child {
            margin-bottom: -5px;
            font-size: 12px;
            font-weight: bold;
          }
        }
      }
    }
  }

  @keyframes changeColor {
    0% {
      background: #a10000;
    }
    50% {
      background: #df0404;
    }
    100% {
      background: #a10000;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.1, 1.1);
    }
    100% {
      transform: scale(1, 1);
    }
  }

  .Toastify__progress-bar--default {
    background: var(--secondary-background-color) !important;
  }

  .description-auction {
    p {
      font-size: 16px !important;
    }
  }

  .description-lots {
    font-size: 16px !important;
    p {
      font-size: 16px !important;
    }
  }

  .item-myaccount {
    margin-left: 2rem;
    cursor: pointer;
  }

  @media screen and (max-width: 990px) {
    .item-myaccount {
      margin-left: 0.5rem;
    }

    .video-youtube {
      height: auto;
      min-height: auto !important;
    }
  }

  .not-border-radius {
    img {
      border-radius: 0px !important;
      border-top-left-radius: 0px !important;
      border-top-right-radius: 0px !important;
    }
  }

  .tabs-selection {
    font-size: 14px;
    button {
      background: transparent;
      border: none;
      &:hover {
        color: var(--primary-background-color);
      }
      &:focus {
        outline: none;
      }
    }
    button.active {
      color: var(--primary-background-color);
    }
  }

  .video-youtube {
    min-height: 450px;
    div:first-child {
      display: contents;
    }
    iframe {
      min-height: 450px;
    }
  }
}

.auction-iframe {
  border: none;
}
