.secondary-menu {
  background-color: var(--secondary-background-color);
  color: var(--text-color-with-secondary);
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  /*border-bottom: 1px solid var(--primary-background-color);*/
  .container {
    padding-top: 1rem !important;
    @media screen and (max-width: 768px) {
      padding-bottom: 2rem !important;
    }
  }
  img {
    width: 150%;
    @media screen and (max-width: 768px) {
      width: 75%;
    }
  }
  .box-items {
    display: flex;
    margin: 0 auto;
    padding: 0.5rem 0rem;
    .start-items {
      flex: 1;
      display: flex;
    }
    .end-items {
      display: flex;
      justify-content: flex-end;
      align-content: center;
      .item {
        margin-right: 0px;
      }
    }
    .item {
      display: inline-flex;
      align-items: center;
      margin-right: 20px;
      .icon {
        width: 16px;
        height: 16px;
        background-size: 100%;
        background-repeat: no-repeat;
        margin-right: 5px;
        &.wp {
          background-image: url("../../../assets/images/phone.svg");
        }
        &.mail {
          height: 14px;
          width: 17px;
          background-image: url("../../../assets/images/mail.svg");
        }
        &.signin {
          width: 18px;
          height: 14px;
          background-image: url("../../../assets/images/signin.svg");
        }
        &.wp-white {
          background-image: url("../../../assets/images/phone-white.svg");
        }
        &.mail-white {
          height: 14px;
          width: 17px;
          background-image: url("../../../assets/images/mail-white.svg");
        }
        &.signin-white {
          width: 18px;
          height: 14px;
          background-image: url("../../../assets/images/signin-white.svg");
        }
      }
    }
    a {
      color: var(--text-color-with-secondary);
    }
  }
  .phone-information {
    position: absolute;
    right: 3rem;
    top: 0rem;
    bottom: 0rem;
    a {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      &:hover {
        color: var(--text-color-with-secondary);
      }
    }
  }

  .personal-information {
    position: absolute;
    left: 3rem;
    top: 0rem;
    bottom: 0rem;
    display: flex;
    align-items: center;
    p {
      font-size: 16px;
      font-weight: 800;
      text-shadow: 0.25px 0.25px 0.5px rgb(224 20 47 / 50%);
      letter-spacing: 0.5px;
      line-height: 25px;
      text-transform: uppercase;
      color: var(--primary-background-color);
    }
  }

  .personal-information.right {
    right: 3rem;
    left: auto;
  }

  .personal-information-mobile {
    margin-top: 0.5rem;
    text-align: center;
    p {
      margin-bottom: 0rem;
      font-size: 13px;
      font-weight: 800;
      text-shadow: 0.25px 0.25px 0.5px rgb(224 20 47 / 50%);
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: var(--primary-background-color);
    }
  }
}

.login-with-code {
  margin-top: 1rem;
}

.access-code {
  border: none;
  background: #06060694;
  color: #fff;
  border-radius: 5px;
  padding: 2px 10px;
  margin-left: 10px;
  &:focus {
    outline: none;
  }
}

.navbar {
  padding: 0rem !important;
}

.navbar-toggler-icon {
  margin-right: 2rem;
}

.menu-button-name {
  color: var(--text-color-with-secondary) !important;
  font-weight: bold !important;
  width: max-content;
  margin-left: 2rem !important;
  border-radius: 50rem !important;
  padding: 0.5rem 1.5rem !important;
  font-size: 14px;
  font-weight: bold;

  svg {
    margin-right: 0.5rem;
  }

  .list-group-item {
    font-weight: normal;
    min-width: 180px;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px !important;
    padding: 0.25rem 1rem !important;
    margin-left: 1rem !important;
  }
}

.primary-menu {
  position: relative;
  .box-items {
    width: 100%;
    background-color: var(--primary-background-color);
    -webkit-box-shadow: 0 7px 30px -6px #abbed1;
    -moz-box-shadow: 0 7px 30px -6px #abbed1;
    box-shadow: 0 7px 30px -6px #abbed1;
    color: #1e3756;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    position: absolute;
    top: -28px;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    .link {
      .item {
        text-align: center;
      }
    }
    .start-items {
      flex: 1;
      display: flex;
      a {
        margin: 0px;
      }
      .logo {
        height: 40px;
      }
    }
    .end-items {
      flex: 2;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .item {
        margin-right: 0px;
        .link {
          width: 110px;
        }
      }
    }
    a {
      color: var(--text-color-with-primary);
    }
    .a-hello {
      margin-right: 2rem;
    }
    .button-hello {
      justify-content: end;
    }
  }

  @media screen and (max-width: 768px) {
    width: 90% !important;
    .box-items {
      top: -20px;
      .a-hello {
        margin-right: 0rem;
      }
      .button-hello {
        justify-content: flex-start;
      }
    }
  }
}

.access-code-box {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000000a3;
  z-index: 12;

  .dialog {
    background-color: #fff;
    padding: 2rem;
    margin: 0 auto;
    max-width: 500px;
    margin-top: 1%;
    border-radius: 10px;
    text-align: center;

    .title-box .title {
      font-size: 22px;
    }

    .custom-input {
      display: flex;
      margin: 1rem 2rem;
      overflow: hidden;
      border-radius: 10px;
      position: relative;

      input {
        flex: 1;
        padding: 5px;
        background-color: #ccc;
        border: none;
        text-indent: 10px;
        &:focus {
          outline: none;
        }
      }

      .action {
        position: relative;
        background-color: var(--primary-background-color);
        border: none;
        padding: 0px;
        &:focus {
          outline: none;
        }
        img {
          padding: 2px 8px;
          height: 35px;
        }
      }
    }

    .separator {
      width: 150px;
      border-top: 1px solid #ccc;
      margin: 0 auto;
      margin-top: 1rem;
    }
    p {
      padding: 1rem;
      color: #ccc;
      text-align: center;
      margin-bottom: 0px;
    }

    .request {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.1rem;
      text-transform: uppercase;
      border: none;
      border-radius: 10px;
      padding: 10px 15px;
      color: var(--text-color-with-secondary);
      background-color: var(--secondary-background-color);
    }
  }
}

.modal-term-and-conditions {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000000a3;
  z-index: 12;

  .dialog {
    background-color: #fff;
    margin: 0 auto;
    width: max-content;
    margin-top: 1%;
    border-radius: 10px;

    .dialog-content {
      max-height: 80vh;
      overflow-x: scroll;
    }

    .accept {
      width: 100%;
      text-align: center;
      button {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .access-code {
    margin-left: 0px;
  }

  .access-code-box .dialog {
    margin-top: 20%;
  }
}

.primary-menu.container {
  padding: 0rem;
}

@media screen and (max-width: 768px) {
  .side-collapse-container {
    width: 100%;
    position: relative;
    left: 0;
    transition: left 0.4s;
  }
  .side-collapse-container.out {
    left: 200px;
  }
  .side-collapse {
    top: 0px;
    bottom: 0;
    left: 0;
    width: 70%;
    position: fixed;
    overflow: hidden;
    transition: width 0.4s;
  }
  .side-collapse.in {
    width: 0;
  }

  .navbar-inverse {
    background-color: var(--primary-background-color);
    border-color: var(--primary-background-color);
    z-index: 999;
  }
}
