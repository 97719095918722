.return-to-list {
  cursor: pointer;
  text-decoration: none;
  font-size: 1rem;
  color: var(--primary-background-color);
}

.video-youtube {
  min-height: 450px;
  div:first-child {
    display: contents;
  }
  iframe {
    min-height: 450px;
  }
}
