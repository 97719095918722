$intercalate-background-color: var(--intercalate-background-color);

.facebook-button-class {
  padding: 0.2rem 2rem;
  background: #1876f2;
  border: white;
  color: white;
  width: 100%;
  border-radius: 50rem !important;
  border: 1px solid transparent;
  font-family: "TrebuchetMS", "Trebuchet MS";
  i {
    margin-right: 8px;
  }
  height: 38px;
}

.register-area {
  background-color: white;
  -webkit-box-shadow: 7 4px 30px -6px #abbed1;
  -moz-box-shadow: 0 7px 30px -6px #abbed1;
  box-shadow: 0 7px 30px -6px #abbed1;
}

.register-background {
  background-image: linear-gradient(
    to right,
    $intercalate-background-color 50%,
    rgba(0, 0, 0, 0) 20%
  );
  padding-bottom: 3rem !important;
  padding-top: 3rem !important;
}

.signup-content {
  .signup-form {
    border-radius: 0.75rem;
  }
  .btn-register {
    height: 38px;
    width: 100%;
  }
  .forgot-password {
    font-size: 14px;
    text-decoration: underline;
    color: var(--primary-background-color);
  }
  .form-group {
    input,
    select {
      border-radius: 0.45rem;
    }
  }

  @media screen and (max-width: 768px) {
    .btn-register {
      margin-bottom: 1rem;
    }
    .register-background {
      padding-top: 0rem !important;
      padding-bottom: 0rem !important;
      background: white !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .register-background {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
    background: white !important;
  }
}
