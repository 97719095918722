.products-page-gallery {
  margin-bottom: 1rem;

  .image-gallery {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image-gallery-content {
    max-width: 100%;
  }

  .image-gallery-icon-fullscreen {
    position: absolute;
    top: 0rem;
    background: transparent;
    border: none;
    width: 100%;
    height: 100%;
    text-align: right;
    padding: 1.5rem;

    @media (max-width: 768px) {
      padding: 1rem;
    }

    img {
      cursor: pointer;
      width: 2rem;
      background: #fff;
      border-radius: 100%;
    }
  }
  .zoom-in {
    &:hover {
      cursor: zoom-in;
    }
  }
  .zoom-out {
    &:hover {
      cursor: zoom-out;
    }
  }

  .image-gallery-content.fullscreen {
    background: #000 !important;
  }
}
