#auction-detail-in-person {
  .pb-60 {
    padding-bottom: 4rem;
  }

  .ptb-60 {
    padding-bottom: 4rem;
    padding-top: 4rem;
  }

  .box-top-subastas {
    background-color: white;
    -webkit-box-shadow: 7 4px 30px -6px #abbed1;
    -moz-box-shadow: 0 7px 30px -6px #abbed1;
    box-shadow: 0 7px 30px -6px #abbed1;
    padding: 20px;
  }

  .box-subastas {
    background-image: linear-gradient(
      to right,
      var(--intercalate-background-color) 50%,
      rgba(0, 0, 0, 0) 20%
    );
    padding: 25px;
    margin-top: 2rem;

    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
  }

  h2 {
    font-size: 30px;
    font-weight: bold;
  }
  .state-title {
    font-weight: 300;
    margin-right: 0.25rem;
    color: #929292;
  }
  .state-content {
    text-transform: uppercase;
    color: var(--secondary-background-color);
  }
  .date-from-to {
    margin-top: 1rem;
    margin-bottom: 1rem;
    background: #f0f0f0;
    padding: 1rem 1.5rem;
    border-radius: 0.75rem;
    font-size: 20px;
    color: #929292;
  }

  .card-lot {
    margin-bottom: 2rem;
    box-shadow: 0px 8px 16px 0px rgba(171, 190, 209, 0.4);
    padding: 0rem;
    border-radius: 20px;

    .single-collections-box {
      img {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
      }
    }

    .product-image {
      position: relative;
      height: 100%;
      img {
        width: 100%;
        object-fit: cover;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        height: 250px;
      }
    }

    button {
      border-radius: 50rem;
      width: 100%;
      font-size: 13px;
      font-weight: bold;
      height: max-content;
    }

    .title-lot {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 0.25rem;
    }

    .quantity-lot {
      margin-bottom: 0px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 13px;
    }

    .description-without {
      margin-bottom: 0px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 13px;
    }

    .description-with {
      height: 25px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: 0;
      font-size: 13px;
    }

    .date-close-and-price {
      margin-bottom: 0px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 13px;
    }

    .best-offer-user {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 13px;
    }

    .actual-offer {
      margin-bottom: 0rem;
      font-size: 13px;
    }

    .winning {
      background-color: var(--button-offer-winning) !important;
      border-color: var(--button-offer-winning) !important;
    }

    .not_offered {
      background-color: var(--button-offer-not_offered) !important;
      border-color: var(--button-offer-not_offered) !important;
      color: var(--text-color-with-secondary) !important;
    }

    .not_auctioned {
      background-color: var(--button-offer-not_auctioned) !important;
      border-color: var(--button-offer-not_auctioned) !important;
    }

    .auctioned {
      background-color: var(--button-offer-auctioned) !important;
      border-color: var(--button-offer-auctioned) !important;
    }

    .login-to-offer {
      background-color: var(--primary-background-color) !important;
      border-color: var(--primary-background-color) !important;
    }

    input {
      border-radius: 50rem;
      height: 30px;
      font-size: 13px;
      border: 1px solid black;
    }
  }

  .timeclock {
    background: #df0404;
    text-align: center;
    padding: 0.2rem;
    animation: changeColor 1s infinite;
    div {
      color: white;
      font-weight: bold;
    }
  }

  .auction-in-proccess {
    width: max-content;
    animation: zoominoutsinglefeatured 1s infinite;
    max-width: 100%;
  }

  @keyframes changeColor {
    0% {
      background: #a10000;
    }
    50% {
      background: #df0404;
    }
    100% {
      background: #a10000;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.1, 1.1);
    }
    100% {
      transform: scale(1, 1);
    }
  }

  .Toastify__progress-bar--default {
    background: var(--secondary-background-color) !important;
  }

  .description-auction {
    p {
      font-size: 16px !important;
    }
  }

  .description-lots {
    font-size: 16px !important;
    p {
      font-size: 16px !important;
    }
  }

  .item-myaccount {
    margin-left: 2rem;
    cursor: pointer;
  }

  @media screen and (max-width: 990px) {
    .item-myaccount {
      margin-left: 0.5rem;
    }
  }

  .video-youtube {
    min-height: 450px;
    div:first-child {
      display: contents;
    }
    iframe {
      min-height: 450px;
    }
  }

  .images-fullscreen {
    position: fixed;
    top: 0;
    z-index: 15;
    background: #000000e8;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-height: 80vh;
      width: auto;
      max-width: 100%;
    }

    .carousel {
      max-width: 100%;
    }
  }
}
