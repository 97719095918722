footer {
  background-color: var(--footer-background);
  padding: 0rem;
  padding-top: 4rem;
  border-top: 1px solid #e8e8e8;

  .footer-information {
    padding: 0rem;
    .footer-section {
      @media screen and (max-width: 768px) {
        text-align: center;
        h6 {
          margin-top: 1rem;
          margin-bottom: 1rem !important;
        }
      }
    }
  }
  p,
  span {
    font-size: 14px;
    color: var(--footer-text-color);
  }
  h6 {
    color: var(--footer-text-color);
  }
  .link,
  a {
    color: var(--footer-text-color);
    &:hover {
      color: var(--footer-text-color);
    }
  }

  .footer-text-color {
    color: var(--footer-text-color);
  }
}
