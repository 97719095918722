@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700&display=swap");

$black-color: #222222;
$white-color: #ffffff;
$transition: 0.5s;
$paragraph-color: #666666;
$primary-background-color: var(--primary-background-color);
$secondary-background-color: var(--secondary-background-color);
$text-color-with-primary: var(--text-color-with-primary);
$text-color-with-secondary: var(--text-color-with-secondary);
$intercalate-background-color: var(--intercalate-background-color);
$button_offer_not_offered: var(--button-offer-not-offered);

p,
span,
h1,
h2,
h3,
h4,
h5,
label,
div {
  font-family: var(--family-font) !important;
}

.primary-background {
  background-color: $primary-background-color;
}

.text-primary-background {
  color: $primary-background-color;
}

.secondary-background {
  background-color: $secondary-background-color;
}

.text-secondary-background {
  color: $secondary-background-color;
}

.primary-color {
  color: $text-color-with-primary;
}

.secondary-color {
  color: $text-color-with-secondary;
}

.code-access-background {
  background-color: var(--code_access_background);
}

.border-secondary-color {
  border-color: $text-color-with-secondary !important;
}

.text-black {
  color: $black-color;
}

.half-background,
.intercalate-background {
  background-color: $intercalate-background-color;
}

.text-cards-primary {
  color: $text-color-with-secondary;
}

.button-offer-not-offered {
  background-color: $button_offer_not_offered;
  border-color: $button_offer_not_offered;
}

.secondary-color {
  //color: #B09056;
  color: $text-color-with-secondary;
}

.text_color_with_primary {
  color: $text-color-with-primary;
}

.section {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.border-title-third {
  border-left-style: solid;
  border-left-width: 10px;
}

.border-title-primary {
  border-left-color: $text-color-with-secondary;
  border-left-style: solid;
  border-left-width: 10px;
}

.border-title {
  border-left-style: solid;
  border-left-width: 10px;
}

.color-secondary {
  color: var(--secondary-background-color);
}

.title-buy-sell {
  h1 {
    font-size: 40px;

    @media screen and (max-width: 768px) {
      font-size: 28px;
    }
  }
}

.social-networks {
  background-color: var(--primary-background-color);
  width: 30px;
  height: 30px;

  svg {
    color: $text-color-with-primary;
  }
}

.product-info {
  list-style-type: none;
  padding: 0;
  margin: {
    top: 2rem;
    bottom: 0;
  }
  li {
    font-size: 15px;
    color: $black-color;
    margin-bottom: 2px;
    font-weight: 500;

    &:last-child {
      margin-bottom: 0;
    }
    span {
      color: $paragraph-color;
    }
    a {
      display: inline-block;
      font-weight: 500;
      color: $black-color;

      &:hover {
        color: $black-color;
      }
    }
  }
}

.faq-accordion {
  margin-top: 2rem;
  .accordion {
    padding: 0rem;
  }
}

.contact-list {
  list-style-type: none;
  padding-left: 0;
  margin: {
    top: 20px;
    bottom: 25px;
  }
  li {
    color: #666666;
    margin-bottom: 10px;
    font-size: 13px;
    position: relative;
    padding-left: 20px;

    i {
      color: #000;
      //position: absolute;
      left: 0;
      top: 2px;
    }
    a {
      color: #666666;
      display: inline-block;

      &:hover,
      &:focus {
        color: #000;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.whatsapp {
  position: fixed;
  width: 3.5rem;
  height: 3.5rem;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  color: #fff !important;
  border-radius: 3rem;
  text-align: center;
  font-size: 2.25rem;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    text-decoration: none;
  }
}

.desktop {
  visibility: visible;
}

.mobile {
  display: none;
}

.go-top {
  position: fixed;
  cursor: pointer;
  bottom: 25px;
  right: 80px;
  z-index: 4;
  width: 40px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  border-radius: 100%;
  background: var(--primary-background-color);
  i {
    color: var(--text-color-with-primary);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;

    &:last-child {
      opacity: 0;
      visibility: hidden;
      top: 60%;
    }
  }
  &:hover,
  &:focus {
    i {
      &:first-child {
        opacity: 0;
        top: 0;
        visibility: hidden;
      }
      &:last-child {
        opacity: 1;
        visibility: visible;
        top: 50%;
      }
    }
  }
}

.contact-info {
  h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 0;
  }
  .contact-list {
    list-style-type: none;
    padding-left: 0;
    margin: {
      top: 20px;
      bottom: 25px;
    }
    .contact-icon {
      //background-color: #F0C543;
      width: 40px;
      height: 40px;
      text-align: center;
      line-height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    li {
      //margin-bottom: 10px;
      font-size: 13px;
      padding-left: 20px;
      //height: 60px;
      border-radius: 5px;
      background-color: #fff;
      padding: 20px 20px 20px 10px;
      align-items: center;
      //margin-bottom:30px;

      @media screen and (max-width: 768px) {
        font-size: 11px;
      }

      i {
        color: black;
        font-size: 20px;
      }
      a {
        display: inline-block;

        &:hover,
        &:focus {
          color: gray;
        }
      }
      &:last-child {
        //margin-bottom: 0;
      }
    }
  }
  .opening-hours {
    padding-left: 0;
    list-style-type: none;
    margin: {
      bottom: 25px;
      top: 15px;
    }
    li {
      margin-bottom: 8px;
      font-size: 13px;

      span {
        color: black;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .product-info {
    list-style-type: none;
    padding: 0;
    margin: {
      top: 2rem;
      bottom: 0;
    }
    li {
      font-size: 15px;
      color: $black-color;
      margin-bottom: 2px;
      font-weight: 500;

      &:last-child {
        margin-bottom: 0;
      }
      span {
        color: $paragraph-color;
      }
      a {
        display: inline-block;
        font-weight: 500;
        color: $black-color;

        &:hover {
          color: $black-color;
        }
      }
    }
  }
  .social {
    padding-left: 0;
    list-style-type: none;
    margin: {
      bottom: 0;
      top: 15px;
    }
    li {
      display: inline-block;
      margin-right: 4px;

      a {
        border: 1px solid #eeeeee;
        width: 35px;
        height: 35px;
        font-size: 13px;
        line-height: 35px;
        text-align: center;
        border-radius: 50%;

        &:hover,
        &:focus {
          background-color: black;
          color: white;
          border-color: black;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.contact-form {
  input {
    color: $text-color-with-secondary;
    background: transparent;
    padding-left: 1rem;
    border: 1px solid #000000;
  }
  textarea {
    border-radius: 25px !important;
    padding-top: 10px;
    padding-left: 1rem;
    background: transparent;
    border: 1px solid #000000;
  }
  input::placeholder {
    color: $text-color-with-secondary;
    opacity: 1;
  }
  textarea::placeholder {
    color: $text-color-with-secondary;
    opacity: 1;
  }
  input:hover,
  textarea:hover,
  input:focus,
  textarea:focus {
    background: transparent;
  }
  h3 {
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 0;
  }
  h2 {
    color: $text-color-with-secondary;
  }
  #contactForm {
    margin-top: 20px;

    label {
      font-size: 13px;
      text-transform: uppercase;

      span {
        text-transform: lowercase;
      }
    }
    .form-group {
      margin-bottom: 20px;
    }
    .form-control {
      font-size: 13px;
      height: 40px;
      border: 1px solid $black-color;
      transition: 0.5s;
      border-color: black;
      background-color: transparent;
      color: $text-color-with-secondary;
    }
    textarea {
      height: auto !important;
      padding-top: 15px;
    }
    .list-unstyled {
      padding-left: 0;
      list-style-type: none;
      margin: {
        bottom: 0;
        top: 8px;
      }
      li {
        font-size: 13px;
        color: red;
      }
    }
    #msgSubmit {
      margin: 0;
      font-size: 18px;

      &.text-danger,
      &.text-success {
        margin-top: 15px;
      }
    }
  }
}

.contact-banner {
  height: 600px;

  @media screen and (max-width: 768px) {
    height: 300px;
  }
}

.list-lote {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    color: #24393e;
    font-size: 15px;
    font-weight: bold;

    &:last-child {
      margin-right: 0;
    }
  }
}

.products-details-area {
  background-color: white;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  -webkit-box-shadow: 7 4px 30px -6px #abbed1;
  -moz-box-shadow: 0 7px 30px -6px #abbed1;
  box-shadow: 0 7px 30px -6px #abbed1;
}

.product-box {
  background-image: linear-gradient(
    to right,
    var(--intercalate-background-color) 45%,
    rgba(0, 0, 0, 0) 20%
  );

  @media screen and (max-width: 768px) {
    background-image: linear-gradient(
      to right,
      var(--intercalate-background-color) 55%,
      rgba(0, 0, 0, 0) 20%
    );
  }
}

.product-details-content {
  h3 {
    font-size: 22px;
    margin-bottom: 8px;

    a {
      color: $black-color;

      &:hover {
        color: $black-color;
      }
    }
  }
  p {
    margin-bottom: 0;
  }
  .price {
    margin-bottom: 5px;

    span {
      display: inline-block;
      font-size: 18px;
      color: $black-color;
    }
  }
  .product-review {
    margin-bottom: 12px;

    .rating {
      display: inline-block;
      padding-right: 8px;

      i {
        color: #ffba0a;
        margin-right: 2px;
      }
    }
    .rating-count {
      display: inline-block;
      color: $black-color;
      border-bottom: 1px solid $black-color;
      line-height: initial;
    }
  }

  .wishlist-compare-btn {
    margin-top: 20px;

    .btn {
      i {
        margin-right: 3px;
      }
      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }
  .product-color-switch {
    margin-top: 8px;

    h4 {
      font-size: 16px;
      color: $paragraph-color;
      margin-bottom: 6px;
    }
    ul {
      list-style-type: none;
      margin: {
        bottom: 0;
        top: -4px;
        left: -4px;
      }
      padding: {
        left: 0;
      }
      li {
        display: inline-block;
        margin: {
          left: 4px;
          top: 4px;
        }
        a {
          display: inline-block;
          position: relative;
          border: 1px solid transparent;
          width: 25px;
          height: 25px;
          border-radius: 50%;

          &::before {
            position: absolute;
            content: "";
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: #eeeeee;
            border-radius: 50%;
            margin: 2px;
            transition: $transition;
          }
          &:hover,
          &:focus {
            border-color: $black-color;
          }
          &.color-white {
            &::before {
              background: $white-color;
              border: 1px solid #eeeeee;
            }
          }
          &.color-black {
            &::before {
              background: $black-color;
            }
          }
          &.color-red {
            &::before {
              background: red;
            }
          }
          &.color-blue {
            &::before {
              background: blue;
            }
          }
          &.color-green {
            &::before {
              background: green;
            }
          }
          &.color-yellow {
            &::before {
              background: yellow;
            }
          }
          &.color-yellowgreen {
            &::before {
              background: yellowgreen;
            }
          }
          &.color-pink {
            &::before {
              background: pink;
            }
          }
          &.color-violet {
            &::before {
              background: violet;
            }
          }
          &.color-teal {
            &::before {
              background: teal;
            }
          }
          &.color-plum {
            &::before {
              background: plum;
            }
          }
          &.color-lime {
            &::before {
              background: lime;
            }
          }
          &.color-blueviolet {
            &::before {
              background: blueviolet;
            }
          }
        }
        &.active {
          a {
            border-color: $black-color;
          }
        }
      }
    }
  }
  .product-size-wrapper {
    margin: {
      top: 8px;
      bottom: 25px;
    }
    h4 {
      font-size: 16px;
      color: $paragraph-color;
      margin-bottom: 8px;
    }
    ul {
      list-style-type: none;
      margin: {
        bottom: 0;
        top: -8px;
        left: -8px;
      }
      padding: {
        left: 0;
      }
      li {
        display: inline-block;
        margin: {
          left: 8px;
          top: 8px;
        }
        a {
          width: 40px;
          height: 35px;
          display: inline-block;
          line-height: 35px;
          border: 1px solid #eeeeee;
          color: #858585;
          text-align: center;

          &:hover,
          &:focus {
            border-color: $black-color;
            color: $black-color;
          }
        }
        &.active {
          a {
            border-color: $black-color;
            color: $black-color;
          }
        }
      }
    }
  }
  .product-add-to-cart {
    .input-counter {
      max-width: 130px;
      min-width: 130px;
      margin-right: 10px;
      text-align: center;
      display: inline-block;
      position: relative;

      span {
        position: absolute;
        top: 0;
        background-color: transparent;
        cursor: pointer;
        color: #d0d0d0;
        width: 40px;
        height: 100%;
        line-height: 50px;
        transition: $transition;

        &.minus-btn {
          left: 0;
        }
        &.plus-btn {
          right: 0;
        }
        &:hover {
          color: $black-color;
        }
      }
      input {
        height: 45px;
        color: $black-color;
        outline: 0;
        display: block;
        border: none;
        background-color: #f8f8f8;
        text-align: center;
        width: 100%;
        font: {
          size: 17px;
          weight: 600;
        }
        &::placeholder {
          color: "#7c7c7c";
          font-size: 14px;
        }
      }
    }
    .btn {
      height: 45px;
      top: -2px;
      position: relative;

      i {
        margin-right: 2px;
      }
    }
  }
  .product-info-btn {
    margin-bottom: 25px;

    a {
      color: $paragraph-color;
      text-transform: uppercase;
      font-weight: 600;
      display: inline-block;
      position: relative;
      line-height: initial;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: -2px;
        width: 0;
        height: 1.5px;
        background: $black-color;
        transition: $transition;
      }
      i {
        color: $black-color;
        margin-right: 3px;
        font-size: 15px;
      }
      &:hover {
        color: $black-color;

        &::before {
          width: 100%;
        }
      }
      &:not(:first-child) {
        margin-left: 15px;
      }
    }
  }
  .buy-checkbox-btn {
    margin-top: 25px;

    input {
      display: none;
    }
    .cbx {
      margin: auto;
      -webkit-user-select: none;
      user-select: none;
      cursor: pointer;

      span {
        display: inline-block;
        vertical-align: middle;
        transform: translate3d(0, 0, 0);

        &:first-child {
          position: relative;
          width: 18px;
          height: 18px;
          border-radius: 3px;
          transform: scale(1);
          vertical-align: middle;
          border: 1px solid #ebebeb;
          transition: all 0.2s ease;
          transition: $transition;

          svg {
            position: absolute;
            top: 3px;
            left: 2px;
            fill: none;
            stroke: $white-color;
            stroke-width: 2;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-dasharray: 16px;
            stroke-dashoffset: 16px;
            transition: all 0.3s ease;
            transition-delay: 0.1s;
            transform: translate3d(0, 0, 0);
            transition: $transition;
          }
          &:before {
            content: "";
            width: 100%;
            height: 100%;
            background: $black-color;
            display: block;
            transform: scale(0);
            opacity: 1;
            border-radius: 50%;
            transition: $transition;
          }
        }
        &:last-child {
          padding-left: 10px;
          color: $paragraph-color;
        }
      }
      &:hover {
        span {
          &:first-child {
            border-color: $black-color;
          }
        }
      }
    }
    .inp-cbx:checked + .cbx span:first-child {
      background: $black-color;
      border-color: $black-color;
      animation: wave 0.4s ease;
    }
    .inp-cbx:checked + .cbx span:first-child svg {
      stroke-dashoffset: 0;
    }
    .inp-cbx:checked + .cbx span:first-child:before {
      transform: scale(3.5);
      opacity: 0;
      transition: all 0.6s ease;
    }
    .item {
      &:not(:first-child) {
        margin-top: 15px;
      }
    }
    .btn {
      display: block;
    }
  }
  .custom-payment-options {
    margin-top: 20px;

    span {
      display: block;
      color: $paragraph-color;
      margin-bottom: 5px;
    }
    .payment-methods {
      a {
        display: inline-block;
        margin-right: 5px;
        margin-top: 5px;
        img {
          width: 40px;
        }
      }
    }
  }
}

@media screen and (max-width: 990px) {
  .desktop {
    display: none !important;
  }
  .mobile {
    display: block;
  }
  .whatsapp {
    display: none;
  }
  .go-top {
    bottom: 10px;
    right: 10px;
    width: 35px;
    height: 35px;
    line-height: 35px;
  }
  .tab-content {
    .tab-pane {
      width: 100%;
      .row {
        width: 100%;
        margin: 0px;
      }
    }
  }
}

.btn-danger {
  background: white;
  color: var(--primary-background-color) !important;
  border: 1px solid var(--primary-background-color);
  &:hover {
    background: white;
    color: var(--primary-background-color);
    border: 1px solid var(--primary-background-color);
    opacity: 0.5;
  }
}

.btn-primary {
  background: var(--primary-background-color);
  color: white;
  border: 1px solid var(--primary-background-color);
  &:hover {
    color: white;
    background: var(--primary-background-color);
    opacity: 0.5;
    border: 1px solid var(--primary-background-color);
  }
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e") !important;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e") !important;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: drop-shadow(2px 2px 2px black) !important;
}

.Toastify__toast--warning {
  background: var(--text-color-with-primary) !important;
  color: var(--primary-background-color) !important;
}

.Toastify__progress-bar--warning {
  background: var(--primary-background-color) !important;
}

/* Estilo CSS */
.password-container {
  position: relative;
  width: 200px;
}

.password-input {
  width: 100%;
}

.toggle-password {
  position: absolute;
  border: none;
  background: none;
  cursor: pointer;
  top: 0;
  bottom: 0;
  right: 1rem;
}
