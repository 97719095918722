.item-auction {
  box-shadow: 0px 8px 16px 0px rgba(171, 190, 209, 0.4);
  border-radius: 10px;
  background: #fff;

  .row {
    height: 100%;
  }

  img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    object-fit: cover;
    max-height: 300px;
    height: 300px;
    min-height: 300px;

    @media screen and (max-width: 768px) {
      height: 200px;
    }
  }

  .col-md-6 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .content-box {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 15px;
    min-height: 100%;
    display: grid;

    h5 {
      margin-bottom: 1rem;
    }
    .line {
      font-size: 14px;
      margin-bottom: 0.25rem;
    }
    button {
      width: 100%;
      border: none;
      color: white;
      background: var(--primary-background-color);
      padding: 0.5rem 1rem;
      border-radius: 30px;
      font-size: 13px;
      margin: auto;

      @media screen and (max-width: 768px) {
        margin-top: 0rem;
      }
    }
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }
}

.item-auction-presencial {
  img {
    width: 100% !important;
    max-height: auto !important;
    height: auto !important;
    min-height: auto !important;
    object-fit: contain !important;

    @media screen and (max-width: 768px) {
      height: auto !important;
    }
  }
}
